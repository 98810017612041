<template>
  <div>
    <Header />
    <div class="logo">
      <img class="logo_pic" src="../../../assets/order/buy_logo.png" alt="" />
    </div>
    <div class="title w-1920">
      <span class="w-1200">申请退换货</span>
    </div>
    <div class="w-1920">
      <div class="lie w-1200">
        <div class="lie-top">
          <div class="lie-top-L">商品名称</div>
          <div class="lie-top-C">订单号</div>
          <div class="lie-top-R">购买数量</div>
        </div>
        <div class="lie-bottom">
          <div class="lie-bottom-L">
            <Uimage :src="list.skuImg" alt="" />
            <div class="miaoshu">
              {{ list.spuName }}
            </div>
          </div>
          <div class="lie-bottom-C">{{ list.orderNo }}</div>
          <div class="lie-bottom-R">{{ list.skuCount }}</div>
        </div>
      </div>
    </div>
    <div class="tishi w-1200">
      *温馨提示：<span>本次售后服务将由第三方卖家为您提供</span>
    </div>

    <div class="from w-1200">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="服务类型" prop="labelPosition">
          <el-radio-group v-model="ruleForm.labelPosition" size="small">
            <el-radio-button label="left">退货</el-radio-button>
            <el-radio-button label="right">换货</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- <div class="fuwu">服务支持：30天保价·15天免费换货·7天免费退货</div> -->
        <el-form-item label="提交数量" prop="num">
          <el-input-number
            size="mini"
            v-model="ruleForm.num"
            :min="1"
            :precision="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="提交原因" prop="region">
          <el-select v-model="ruleForm.region" placeholder="请选择提交原因">
            <el-option label="不想要了" value="不想要了"></el-option>
            <el-option label="商品破损" value="商品破损"></el-option>
            <el-option label="发票问题" value="发票问题"></el-option>
            <el-option label="卖家发错货" value="卖家发错货"></el-option>
            <el-option label="买错了" value="买错了"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="pic">
        <span>图片信息：</span>
        <div>
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </div>
      <div class="ti">为了帮助您更好的解决问题，请上传图片</div>
      <div class="ti1">
        最多可上传5张图片，每张图片大小不超过5M，仅支持bmp,gif,jpg,jpeg格式文件
      </div>
    </div>
    <div class="w-1920">
      <div class="tijiao" @click="submitForm()">提交</div>
    </div>
    <div class="kongbai"></div>
    <!-- <Baoyou /> -->
    <Foot />
  </div>
</template>
<script>
import { post, get } from '@/utils/request';

import Header from '@/components/header.vue';
import Foot from '@/components/foot.vue';
import Baoyou from '@/components/order/baoyou.vue';
export default {
  components: {
    Header,
    Baoyou,
    Foot,
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      ruleForm: {
        labelPosition: 'left',
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        num: 1,
      },
      returnType: '0',
      list: '',
      orderid: '',
      rules: {
        labelPosition: [
          { required: true, message: '请选择服务类型', trigger: 'blur' },
          { message: '请选择服务类型', trigger: 'blur' },
        ],
        num: [{ required: true, message: '请选择数量', trigger: 'blur' }],
        region: [
          { required: true, message: '请选择提交原因', trigger: 'change' },
        ],
      },
    };
  },
  created() {
    this.getdetail(this.$route.params.skuId, this.$route.params.orderId);
  },
  methods: {
    submitForm() {
      if (this.ruleForm.labelPosition == 'left') {
        this.returnType = 0;
      } else {
        this.returnType = 1;
      }
      post('api/orderreturn/applyorder', {
        orderId: this.list.orderId,
        skuId: this.list.skuId,
        skuName: this.list.spuName,
        orderNo: this.list.orderNo,
        returnNum: this.ruleForm.num,
        returnType: this.returnType,
        retrunReason: this.ruleForm.region,
        storeId: this.list.storeId,
        returnNum: this.list.skuCount * this.list.skuPrice,
      }).then((res) => {
        if (res.data.msg == '操作成功') {
          this.$message.success({
            message: '申请成功',
          });
          this.$router.go(-1);
        } else {
          this.$message.error({
            message: '申请失败',
          });
        }
      });
    },
    getdetail(e, t) {
      get(
        'api/orderbasic/getorderskuitem?skuId=' + e + '&orderId=' + t + ''
      ).then((res) => {
        this.list = res.data.data;
        this.isLoading = false;
      });
    },

    handleRemove(file, fileList) {
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 1175px;
  margin: 0 auto;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo_pic {
    width: 157px;
    height: 44px;
  }
}
.title {
  border-bottom: 3px solid #f5f5f5;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #616161;
  font-weight: 500;
  span {
    width: 1234px;
    margin: auto;
    display: block;
  }
}
.lie {
  height: 145px;
  margin: 30px auto;
  border: 2px solid #f4f4f4;
  &-top {
    height: 43px;
    line-height: 43px;
    background: #f5f5f5;
    margin: 0;
    display: flex;
    color: #333;
    font-size: 16px;
    &-L {
      width: 582px;
      text-align: center;
    }
    &-C {
      width: 331px;
      text-align: center;
    }
    &-R {
      flex: 1;
      text-align: center;
    }
  }
  &-bottom {
    display: flex;
    font-size: 14px;
    color: #343434;
    align-items: center;
    margin: 0;
    &-L {
      display: flex;
      align-items: center;
      width: 582px;
      border-right: 1px solid #d7d5d6;
      height: 103px;
      img {
        width: 134px;
        height: 85px;
        margin-left: 140px;
      }
      .miaoshu {
        width: 200px;
        margin-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &-C {
      height: 103px;
      line-height: 103px;
      width: 331px;
      text-align: center;
      border-right: 1px solid #d7d5d6;
    }
    &-R {
      flex: 1;
      text-align: center;
    }
  }
}
.tishi {
  color: #f04740;
  font-size: 14px;
  width: 1234px;
  margin: 0 auto;
  span {
    color: #737373;
  }
}
.from {
  width: 1152px;
  margin: 60px auto;
  border: 1px solid #cdcdcd;
  padding: 35px 0 36px 82px;
  .fuwu {
    color: #646464;
    font-size: 16px;
    margin-bottom: 25px;
    margin-left: 15px;
  }
  .pic {
    margin-left: 15px;
    display: flex;
  }
  .ti {
    margin-top: 35px;
    color: #666;
    font-size: 13px;
  }
  .ti1 {
    margin-top: 10px;
    color: #737373;
    font-size: 13px;
  }
}
.tijiao {
  width: 157px;
  height: 40px;
  background: #2659f2;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  margin-left: 525px;
  border-radius: 5px;
  margin-bottom: 80px;
  cursor: pointer;
}
</style>
